import format from 'date-fns/format';
import isValid from 'date-fns/isValid';
import startOfDay from 'date-fns/startOfDay';

export type CustomFieldsForm = {
  name: string;
  type: string;
  label: string;
  rules: string[];
};

export function getMaxLength(rules: string[]) {
  const maxRule = rules.find(rule => {
    if (rule.includes('max:')) return rule;
  });
  if (!maxRule) null;
  return parseInt(maxRule.slice(4));
}

export const parseRules = (rules: string[]): Record<string, any>[] => {
  const parsedRules = [];
  rules.forEach(rule => {
    if (rule === 'required') {
      parsedRules.push({ required: true, message: 'Este campo é obrigatório' });
      return;
    }

    if (rule === 'string') {
      parsedRules.push({ whitespace: true, message: 'O campo não pode ficar em branco' });
      parsedRules.push({ type: 'string', message: 'Deve ser um texto' });
      return;
    }
    if (rule === 'number') {
      parsedRules.push({ type: 'number', message: 'Deve ser um número' });
      return;
    }

    const size = rule.slice(0, 3);
    if (size === 'min') {
      parsedRules.push({ min: +rule.slice(4), message: 'Mínimo de ${min} caracteres' });
      return;
    }

    if (size === 'max') {
      parsedRules.push({ max: +rule.slice(4), message: 'Máximo de ${max} caracteres' });
    }
  });

  return parsedRules;
};

export const createPayload = values => {
  const payload = {};
  payload['custom_fields'] = {};

  Object.keys(values).forEach(item => {
    const isCustomField = item.slice(0, 12) === 'custom_field';

    if (isCustomField) {
      const spliced = item.slice(13);
      payload['custom_fields'][spliced] = formatCustomValue(values[item]);
      return;
    }

    if (item === 'is_international_doc') {
      return;
    }

    payload[item] = values[item];
  });

  return payload;
};

export const formatCustomValue = value => {
  const date = new Date(value);
  const isDate = isValid(date);
  if (!isDate) {
    return typeof value === 'string' ? value.trim() : value;
  }
  return format(startOfDay(date), 'yyyy-MM-dd HH:mm:ss');
};
