import React, { useEffect, useState } from 'react';

import { Button, Col, Divider, Form, Input, InputNumber, Modal, Row, Switch, Typography } from 'antd';
import { CustomFieldsForm } from 'components/Shared/CustomFieldsForm';
import { BrazilIcon } from 'components/Shared/Icons';
import { createPayload } from 'helpers/customFieldsHelper';
import { cleanString } from 'helpers/functions';
import { validateCpf } from 'helpers/validations';
import { IEditPresence } from 'interfaces/attendanceList';
import { IEvent } from 'interfaces/event';
import InputMask from 'react-input-mask';
import attendanceListService from 'services/attendanceList';

import Toast from '../Toast';

type EditParticipantDialogProps = {
  data: IEditPresence;
  open: boolean;
  eventDetail: Partial<IEvent>;
  onCancel: () => void;
  onComplete: (newData: IEditPresence) => void;
  changeStatus?: string;
};

const baseRules = [
  { whitespace: true, message: 'O campo não pode ficar em branco' },
  { required: true, message: 'Este campo é obrigatório' }
];

export const EditParticipantDialog = ({
  data,
  eventDetail,
  open,
  onCancel,
  onComplete,
  changeStatus
}: EditParticipantDialogProps) => {
  const customFields = eventDetail.attendance_custom_fields ?? [];
  const hasCustomFields = customFields.length > 0;
  const [form] = Form.useForm();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isInternationalDoc, setIsInternationalDoc] = useState(false);
  const [isInternationalPhone, setIsInternationalPhone] = useState(false);
  const ddi = Form.useWatch('ddi') || '55';
  const phone = Form.useWatch('phone') || '';

  const onFinish = async values => {
    const payload = createPayload({
      ...values,
      document_type: isInternationalDoc ? 'passport' : 'cpf',
      id: data.id,
      status: changeStatus ?? 'presence_confirmed'
    });

    setIsSubmitting(true);

    attendanceListService.editByInviteKey(payload).subscribe(
      () => {
        Toast.show('Dados alterados com sucesso');
        setIsSubmitting(false);
        onComplete(payload as IEditPresence);
      },
      ({ data: err }) => {
        Toast.error(err);
      }
    );
    setIsSubmitting(false);
  };

  const handlePasteDocument = e => {
    const document = e.clipboardData.getData('Text');
    form.setFieldValue('document', cleanString(document));
  };

  const handlePastePhone = e => {
    const phone = e.clipboardData.getData('Text');
    form.setFieldValue('phone', cleanString(phone));
  };

  const onIsInternationalDocChange = boolean => {
    if (boolean) {
      form.setFieldValue('phone', cleanString(form.getFieldValue('phone')));
      form.setFieldValue('document', cleanString(form.getFieldValue('document')));
    }
    form.setFieldValue('is_international_doc', boolean);
    setIsInternationalDoc(boolean);
  };

  useEffect(() => {
    if (!data) return;

    if (data.document_type !== 'cpf') {
      setIsInternationalDoc(true);
      form.setFieldValue('is_international_doc', true);
    }

    if (!data?.phone) return;

    // corrige telefone no formato + (68) 92560-7149
    if (data?.phone?.match(/^\+\ \($/)) {
      form.setFieldValue('phone', data.phone.slice(2));
    }

    // telefone no formato +55 (83) 99856-9846
    if (data?.phone?.match(/(^\+?5{2})/gm)) {
      form.setFieldValue('phone', data?.phone?.slice(3));
    }

    // telefone no formato 15963259785 ou (42) 3626-6261
    if (data?.phone?.match(/(^\([0-9]{2}\))|^[0-9]+/gm)) {
      form.setFieldValue('phone', data?.phone);
    }

    //se número for internacional || condição extra pra numeros no formato +1 999999999
    if (data?.phone?.match(/(^\+(?!55)[0-9]{2})/gm) || data?.phone?.match(/^\+[0-9]\ [0-9]{2}/)) {
      setIsInternationalPhone(true);
      const firstPart = data?.phone?.substring(0, data?.phone?.indexOf(' '));
      const secondPart = data?.phone?.substring(data?.phone?.indexOf(' ') + 1);
      if (firstPart && secondPart) {
        form.setFieldValue('ddi', cleanString(firstPart));
        form.setFieldValue('phone', cleanString(secondPart));
      } else {
        form.setFieldValue('phone', firstPart);
      }
    }
  }, []);

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      width={600}
      centered
      title={
        <>
          <Typography.Title level={4}>Editar Participante</Typography.Title>
          <Divider />
        </>
      }
      footer={[
        <React.Fragment key='primary'>
          <Divider />
          <Form.Item id='edit-participant-submit-button'>
            <Button
              loading={isSubmitting}
              type='primary'
              key='add-EditParticipant-btn'
              htmlType='submit'
              onClick={() => {
                if (form.validateFields()) {
                  form.submit();
                }
              }}
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Aguarde...' : 'Concluir'}
            </Button>
          </Form.Item>
        </React.Fragment>
      ]}
      destroyOnClose
    >
      <Form layout='vertical' form={form} onFinish={values => onFinish(values)} initialValues={{ ddi: '55' }}>
        <Row gutter={[10, 8]}>
          <Col xs={24} md={12}>
            <Form.Item
              name='name'
              label='Nome'
              id={'name-form-item'}
              required
              rules={[
                ...baseRules,
                { min: 4, message: 'Mínimo 4 caracteres' },
                { pattern: /^[A-Za-z\s]+$/, message: 'Apenas letras são permitidas' }
              ]}
              initialValue={data.name}
            >
              <Input required id='presencelist-participantdetails-dialog-name-input' />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              name='email'
              label='E-mail'
              id={'email-form-item'}
              required
              rules={[{ ...baseRules, type: 'email', message: 'Adicione um email válido' }]}
              initialValue={data.email}
            >
              <Input required id='presencelist-participantdetails-dialog-email-input' />
            </Form.Item>
          </Col>
          <Row gutter={[10, 8]}>
            <Col xs={24} md={12}>
              <Form.Item
                name='document'
                id={'doc-form-item'}
                label={isInternationalDoc ? 'Documento' : 'CPF'}
                required
                initialValue={data.document}
                rules={[
                  ...baseRules,
                  () => ({
                    validator(_, value) {
                      if (isInternationalDoc) return Promise.resolve();
                      const cpf = cleanString(value);
                      if (validateCpf(cpf)) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('CPF inválido'));
                    }
                  })
                ]}
              >
                {isInternationalDoc ? (
                  <Input required id='presencelist-participantdetails-dialog-docnumber-input' />
                ) : (
                  <InputMask mask='999.999.999-99' onPaste={handlePasteDocument}>
                    <Input placeholder='Ex.: 999.888.777-66' maxLength={20} key='participant-cpf-input' />
                  </InputMask>
                )}
              </Form.Item>
            </Col>
            <Col xs={24} md={12} style={{ alignContent: 'end' }}>
              <Form.Item
                name='is_international_doc'
                id={'doc-type-form-item'}
                initialValue={data.document_type === 'cpf' ? false : true}
              >
                <div style={{ display: 'flex' }}>
                  <Switch
                    checked={isInternationalDoc}
                    style={{ marginRight: '5px' }}
                    onChange={onIsInternationalDocChange}
                  />
                  <Typography>É documento internacional?</Typography>
                </div>
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Row>
                <Col span={isInternationalPhone ? 4 : 0}>
                  <Form.Item name='ddi' label='DDI'>
                    <InputNumber
                      prefix={
                        ddi === '55' ? (
                          <>
                            <BrazilIcon />
                            <span>+</span>
                          </>
                        ) : (
                          <span>+</span>
                        )
                      }
                    />
                  </Form.Item>
                </Col>

                <Col span={isInternationalPhone ? 20 : 24}>
                  <Form.Item
                    name='phone'
                    label='Telefone'
                    id={'phone-form-item'}
                    required
                    initialValue={data.phone}
                    rules={[...baseRules]}
                  >
                    {isInternationalPhone ? (
                      <Input placeholder='Nº do telefone' maxLength={4} />
                    ) : (
                      <InputMask
                        mask={phone && cleanString(phone)[2] === '3' ? '(99) 9999-9999' : '(99) 99999-9999'}
                        onPaste={handlePastePhone}
                      >
                        <Input placeholder='Código DDD + Nº do telefone' maxLength={20} />
                      </InputMask>
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            {hasCustomFields &&
              customFields.map(field => (
                <CustomFieldsForm key={`${data.id}-${field.name}`} config={field} customFields={data.custom_fields} />
              ))}
          </Row>
        </Row>
      </Form>
    </Modal>
  );
};
