import { Col, DatePicker, Form, Input } from 'antd';
import dayjs from 'dayjs';
import { parseRules } from 'helpers/customFieldsHelper';
import { EventAttendanceCustomFields } from 'interfaces/event';

export type CustomFields = {
  name: string;
  type: string;
  label: string;
  rules: string[];
};

type Props = {
  config: EventAttendanceCustomFields;
  customFields?: Partial<CustomFields>;
  inviteKey?: string;
};

export const CustomFieldsForm = ({ config, customFields, inviteKey }: Props) => {
  const { name, label } = config;
  const isRequired = config.rules.includes('required');

  if (!customFields || Object.keys(customFields).length === 0) {
    customFields = {};
  }

  const customKey = inviteKey ? `${inviteKey}-` : '';

  if (config.type == 'text') {
    return (
      <Col xs={24} md={12}>
        <Form.Item
          name={`${customKey}custom_field.${name}`}
          label={label}
          id={`${customKey}${name}-custom-form-field`}
          key={`${customKey}${name}-custom-form-field`}
          required={isRequired}
          rules={[...parseRules(config.rules)]}
          initialValue={customFields[name]}
        >
          <Input id={`${customKey}${name}-custom-input`} />
        </Form.Item>
      </Col>
    );
  }

  if (config.type == 'date') {
    return (
      <Col xs={24} md={12}>
        <Form.Item
          name={`${customKey}custom_field.${name}`}
          label={label}
          id={`${customKey}${name}-custom-form-field`}
          key={`${customKey}${name}-custom-form-field`}
          rules={[...parseRules(config.rules)]}
          initialValue={!!customFields[name] ? dayjs(customFields[name]) : ''}
        >
          <DatePicker
            id={`${customKey}${name}-custom-input`}
            key={`${customKey}${name}-custom-form-field`}
            format={'DD/MM/YYYY'}
            placeholder='Selecione a data'
            maxDate={dayjs()}
            style={{ width: '100%' }}
          />
        </Form.Item>
      </Col>
    );
  } else {
    throw 'Unknown custom field type ' + config.type;
  }
};
