import { useCallback, useState, useContext, useEffect } from 'react';

import { PictureOutlined } from '@ant-design/icons';
import SaveOutlined from '@ant-design/icons/SaveOutlined';
import { FormHelperText, Typography } from '@material-ui/core';
import {
  Alert,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Switch,
  TextField
} from '@mui/material';
import { Tooltip } from 'antd';
import ImageSelector, { IImageSelectorResult } from 'components/Shared/ImageSelector';
import Toast from 'components/Shared/Toast';
import { isEmpty } from 'helpers/lodash';
import { IEventForm } from 'interfaces/event';
import { useFormContext, Controller } from 'react-hook-form';

import FormContext from '../../../Context';
import TextAdornment from '../../TextAdornment';
import Actions from '../Actions';
import * as S from './styles';

export enum StatusForm {
  INACTIVE = 0,
  ACTIVE = 1
}

const FormOne = () => {
  const [imageSelectorOpen, setImageSelectorOpen] = useState(false);

  const {
    handleEdit,
    isCreate,
    loadingEdit,
    jobsLotPending,
    setCurrentStep,
    setStepsEnables,
    stepsEnables,
    eventCategories
  } = useContext(FormContext);

  const {
    register,
    formState: { errors },
    watch,
    setError,
    handleSubmit,
    setValue,
    clearErrors,
    control,
    getValues
  } = useFormContext<IEventForm>();

  const imageBase64Watch = watch('image_base64');
  const imagePathWatch = watch('image_path');
  const eventCategory = getValues('category_id');

  const handleNext = useCallback(() => {
    const enablesSteps = Array.from(stepsEnables);
    if (!enablesSteps.includes('2')) {
      enablesSteps.push('2');
      setStepsEnables(enablesSteps);
    }

    setCurrentStep('2');
  }, [stepsEnables, setCurrentStep, setStepsEnables]);

  const handleSelectImage = (image: IImageSelectorResult) => {
    if (!image) {
      setImageSelectorOpen(false);

      return;
    }

    setValue('image_base64', image.base64);
    setValue('image_path', undefined);
    setValue('image_name', image.filename);
    setValue('image_id', null);

    setImageSelectorOpen(false);
    clearErrors('image_base64');
  };

  const handleDeleteImage = () => {
    setValue('image_base64', null);
    setValue('image_name', null);
    setValue('image_path', undefined);
    setValue('image_id', null);
    setError('image_base64', { message: 'Selecione uma imagem' });
  };

  const handlePrimaryButtonAction = () => {
    if (control._formValues.active_data_capture && !control._formValues.data_capture_type) {
      Toast.error('Desative ou escolha um método para captação de dados!')
      return;
    }
    if (!isEmpty(errors)) {
      Toast.error('Verifique todos os campos');
      return;
    }
    handleSubmit(isCreate ? handleNext : handleEdit)();
  };

  const [activeReserveTime, setActiveReserveTime] = useState(false);
  const [activeDataCapture, setActiveDataCapture] = useState(false);
  const [activePreCheckout, setActivePreCheckout] = useState(false);
  const [activePostCheckout, setActivePostCheckout] = useState(false);

  const handleChangeActiveReserveTime = (event: React.ChangeEvent<HTMLInputElement>) => {
    setActiveReserveTime(event.target.checked);
    setValue('hasReserveTime', event.target.checked);
    const activeReserveTime = event.target.checked;
    if (!activeReserveTime && getValues('reserve_time') !== StatusForm.INACTIVE) {
      setValue('reserve_time', StatusForm.INACTIVE);
    }
    if (activeReserveTime && getValues('reserve_time') === StatusForm.INACTIVE) {
      setValue('reserve_time', StatusForm.ACTIVE);
    }
  };

  const handleChangeActiveDataCapture = isActive => {
    setActiveDataCapture(isActive);
    if (!isActive) {
      setActivePreCheckout(false);
      setActivePostCheckout(false);
      setValue('active_precheckout', StatusForm.INACTIVE);
      setValue('active_postcheckout', StatusForm.INACTIVE);
      setValue('data_capture_type', '');
    }
  };

  const handleChangeActivePreCheckout = (active: 0 | 1) => {
    if (active === 1) {
      setValue('active_postcheckout', StatusForm.INACTIVE);
      setValue('active_precheckout', StatusForm.ACTIVE);
      setActivePostCheckout(false);
      setActivePreCheckout(true);
    }
    if (active === 0) setActivePreCheckout(false);
  };

  const handleChangeActivePostCheckout = (active: 0 | 1) => {
    if (active === 1) {
      setValue('active_precheckout', StatusForm.INACTIVE);
      setValue('active_postcheckout', StatusForm.ACTIVE);
      setActivePostCheckout(true);
      setActivePreCheckout(false);
    }
    if (active === 0) setActivePostCheckout(false);
  };

  useEffect(() => {
    const postCheckoutActive = getValues('active_postcheckout') === StatusForm.ACTIVE;
    const preCheckoutActive = getValues('active_precheckout') === StatusForm.ACTIVE;

    if (postCheckoutActive || preCheckoutActive) {
      setValue('active_data_capture', StatusForm.ACTIVE);
      setActiveDataCapture(true);
    } else {
      setValue('active_data_capture', StatusForm.INACTIVE);
      setActiveDataCapture(false);
    }

    if (postCheckoutActive) {
      setValue('data_capture_type', 'post_checkout');
    }

    if (preCheckoutActive) {
      setValue('data_capture_type', 'pre_checkout');
    }

    if (getValues('reserve_time') !== StatusForm.INACTIVE) {
      setActiveReserveTime(true);
    }
  }, [getValues, setValue]);

  const primaryButtonActionText = isCreate ? 'Próximo' : loadingEdit || !!jobsLotPending ? 'Salvando' : 'Salvar';

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <S.Title>Dados básicos do seu evento</S.Title>
        <S.Subtitle>
          Por favor, dedique alguns minutos para preencher o formulário abaixo com os detalhes do seu evento.
        </S.Subtitle>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2} alignContent='center'>
          <Grid item xs={12} md={6}>
            {!imageBase64Watch && !imagePathWatch && (
              <Grid
                alignSelf='center'
                container
                item
                justifyContent='center'
                xs={12}
                sx={{
                  cursor: 'pointer',
                  background: '#FFFFFF',
                  border: 'solid 1px #BFBFBF',
                  height: '100%',
                  color: '#00000073'
                }}
                onClick={() => setImageSelectorOpen(true)}
              >
                <Grid container item justifyContent='center' alignItems='center' alignContent={'flex-end'} xs={12}>
                  <PictureOutlined style={{ fontSize: '64px' }} />
                </Grid>
                <Grid container item justifyContent='center' xs={12}>
                  <Typography>Adicionar uma capa</Typography>
                </Grid>
                {errors?.image_base64 && (
                  <Grid container item justifyContent='center' xs={12} sx={{ padding: '10px 0' }}>
                    <S.ErrorMessage>{errors?.image_base64?.message}</S.ErrorMessage>
                  </Grid>
                )}
              </Grid>
            )}
            {(imageBase64Watch || imagePathWatch) && (
              <Grid alignSelf='center' container item justifyContent='center' xs={12}>
                <div className='image-container'>
                  <S.Wrapper onClick={handleDeleteImage}>
                    <img src={imageBase64Watch || imagePathWatch} alt='Preview de foto do evento'></img>
                    <S.CustomizedDeleteIcon />
                  </S.Wrapper>
                  <FormHelperText>Imagem de capa</FormHelperText>
                </div>
              </Grid>
            )}

            {imageSelectorOpen && (
              <ImageSelector
                opened={imageSelectorOpen}
                height={200}
                width={200}
                onComplete={image => handleSelectImage(image)}
              />
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name='title'
                  render={({ field: { onChange, value } }) => (
                    <>
                      <InputLabel>Nome do evento</InputLabel>
                      <TextField
                        fullWidth
                        variant='outlined'
                        InputLabelProps={{
                          shrink: true
                        }}
                        helperText={errors.title?.message}
                        error={!!errors.title}
                        onChange={event => {
                          onChange(event.target.value);
                        }}
                        value={value}
                        id='title-form-one'
                      />
                    </>
                  )}
                />
                <FormHelperText>Esse nome será exibido em todas as plataformas da Eduzz.</FormHelperText>
              </Grid>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name='helpsupport_email'
                  render={({ field: { onChange, value } }) => (
                    <>
                      <InputLabel>Email de suporte</InputLabel>
                      <TextField
                        fullWidth
                        className='help-support'
                        InputLabelProps={{
                          shrink: true
                        }}
                        helperText={errors.helpsupport_email?.message}
                        error={!!errors.helpsupport_email}
                        onChange={event => {
                          onChange(event.target.value.trim());
                        }}
                        value={value}
                        id='help_support-form-one'
                      />
                    </>
                  )}
                />
                <FormHelperText>As dúvidas de seus clientes serão direcionadas para este e-mail</FormHelperText>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <InputLabel>Breve descrição do evento, contextualize seu cliente sobre o conteúdo do evento</InputLabel>
        <TextField
          fullWidth
          variant='outlined'
          helperText={errors.description?.message}
          error={!!errors.description}
          multiline
          InputLabelProps={{
            shrink: true
          }}
          rows={4}
          id='description-form-one'
          {...register('description')}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          control={control}
          name='category_id'
          render={({ field: { onChange, value } }) => (
            <>
              <InputLabel>Categoria do evento</InputLabel>
              <Select
                fullWidth
                variant='outlined'
                defaultValue={eventCategory}
                value={value}
                required
                error={!!errors.category_id}
                id='select-category'
                onChange={event => {
                  onChange(event.target.value);
                }}
              >
                {eventCategories.map(category => {
                  return (
                    <MenuItem key={category.id} value={category.id}>
                      {category.title}
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText error>{errors.category_id?.message}</FormHelperText>
            </>
          )}
        />

        {watch('category_id') === 'CUSTOM_CATEGORY' && (
          <Controller
            control={control}
            name='custom_category_title'
            render={({ field: { onChange, value } }) => (
              <>
                <InputLabel>Categoria personalizada</InputLabel>
                <TextField
                  fullWidth
                  variant='outlined'
                  InputLabelProps={{
                    shrink: true
                  }}
                  helperText={errors.custom_category_title?.message}
                  value={value}
                  error={!!errors.custom_category_title}
                  onChange={onChange}
                  id='custom-category-title'
                />
              </>
            )}
          />
        )}
      </Grid>

      <Grid item xs={12}>
        <S.Title>Captação de dados dos participantes durante a compra</S.Title>
        <S.Subtitle>
          Habilite para coletar os dados de todos os participantes durante a compra, antes ou depois do pagamento. Caso
          desabilitado, o comprador poderá transferir os ingressos somente após a compra, acessando a Blinket.
        </S.Subtitle>
      </Grid>

      <Grid item xs={12} md={12}>
        <Controller
          control={control}
          name='active_data_capture'
          render={({ field: { onChange, value } }) => (
            <FormControlLabel
              style={{ marginBottom: 10 }}
              label={`Captação de dados está ${value ? 'ativada' : 'desativada'}.`}
              control={
                <Switch
                  onChange={event => {
                    const active = event.target.checked ? true : false;
                    setActiveDataCapture(active);
                    handleChangeActiveDataCapture(active);
                    onChange(active);
                  }}
                  checked={Boolean(value)}
                  id='switch-data-capture'
                />
              }
            />
          )}
        />
        {activeDataCapture && (
          <Grid item xs={12} md={12}>
            <FormControl fullWidth>
              <InputLabel>Selecione o momento da captação dos dados</InputLabel>
              <Controller
                control={control}
                name='data_capture_type'
                render={({ field: { onChange, value } }) => (
                  <Select
                    labelId='data-capture-select-label'
                    value={value || ''}
                    onChange={event => {
                      const selectedType = event.target.value;
                      onChange(selectedType);
                      if (selectedType === 'post_checkout') {
                        handleChangeActivePostCheckout(StatusForm.ACTIVE);
                        handleChangeActivePreCheckout(StatusForm.INACTIVE);
                      } else if (selectedType === 'pre_checkout') {
                        setActiveReserveTime(true);
                        setValue('reserve_time', 15);
                        handleChangeActivePreCheckout(StatusForm.ACTIVE);
                        handleChangeActivePostCheckout(StatusForm.INACTIVE);
                      }
                    }}
                    label='Selecione o momento da captação dos dados'
                  >
                    <MenuItem value='pre_checkout'>Antes do Pagamento (Pré-Checkout)</MenuItem>
                    <MenuItem value='post_checkout'>Depois do Pagamento (Página de Obrigado)</MenuItem>
                  </Select>
                )}
              />
            </FormControl>
            {control._formValues.data_capture_type === 'pre_checkout' && (
              <>
                <Alert severity='info' style={{ marginTop: 8 }}>
                  Captar dados antes do pagamento adiciona uma etapa ao processo de compra.
                </Alert>
                <Alert severity='info'>
                  Para páginas de venda não fornecidas pela Blinket, você deve utilizar o
                  <Link
                    href='https://ajuda.eduzz.com/hc/pt-br/articles/4406141586971-Como-adicionar-o-Script-Widget-em-minha-p%C3%A1gina-de-vendas-Blinket'
                    target='_blank'
                    rel='noopener'
                    sx={{ color: '#0000EE' }}
                    id='link-widget'
                  >
                    {' '}
                    script widget
                  </Link>
                  .
                </Alert>
              </>
            )}
            {control._formValues.data_capture_type === 'post_checkout' && (
              <Alert severity='info' style={{ marginTop: 8 }}>
                Captar dados depois do pagamento o usuário já terá realizado a compra.
              </Alert>
            )}

            {!control._formValues.data_capture_type && (
              <Alert severity='info' style={{ marginTop: 8 }}>
                Escolha o tipo de captação: <strong>Pós-Checkout</strong> para solicitar os dados após o pagamento, ou{' '}
                <strong>Pré-Checkout</strong> para solicitar os dados antes do pagamento.
              </Alert>
            )}
          </Grid>
        )}
        {!activeDataCapture && (
          <Alert severity='info'>
            Ao ativar esta ferramenta, você poderá escolher entre o preenchimento de dados do comprador antes ou depois
            do checkout.
          </Alert>
        )}
      </Grid>

      <Grid item xs={12}>
        <S.FormTitle>Configurações extras</S.FormTitle>
      </Grid>

      <Grid item xs={12}>
        <FormControlLabel
          control={
            <S.CustomSwitch
              disabled={activePreCheckout}
              checked={!activePreCheckout && activeReserveTime}
              onChange={handleChangeActiveReserveTime}
            />
          }
          label={`Tempo de reserva de ingressos está ${activeReserveTime ? 'ativado' : 'desativado'}.`}
          sx={{ color: 'black' }}
          id='check-reserve-time'
        />

        <TextField
          fullWidth
          disabled={!activeReserveTime || activePreCheckout}
          InputProps={{
            endAdornment: <TextAdornment>{watch('reserve_time') === 1 ? 'Minuto' : 'Minutos'}</TextAdornment>
          }}
          variant='outlined'
          type='number'
          helperText={errors.reserve_time?.message}
          error={!!errors.reserve_time}
          id='reserve_time-form-one'
          {...register('reserve_time')}
        />
      </Grid>
      <Grid item xs={12}>
        {!activePreCheckout && activeReserveTime && (
          <Alert severity='warning'>
            Desabilite esta funcionalidade se for vender seus ingressos em um lançamento, pois assim não haverá reserva
            de ingressos no checkout. Tempo máximo de 15 minutos.
          </Alert>
        )}
        {activePreCheckout && (
          <Alert severity='warning'>
            O tempo de reserva é fixado em 15 minutos quando a opção "Preenchimento de dados pré-checkout" está
            habilitada, desabilite-a caso queira alterar o tempo de reserva.
          </Alert>
        )}
      </Grid>
      <Grid item xs={12}>
        <Actions>
          <Tooltip defaultOpen={!!jobsLotPending} open={!!jobsLotPending} title={jobsLotPending} color='#ffbc00'>
            <Actions.Button
              onClick={handlePrimaryButtonAction}
              size='large'
              id={isCreate ? 'step-forward-btn-form-1' : 'save-btn-form-1'}
              type='primary'
              icon={!isCreate && <SaveOutlined />}
              disabled={loadingEdit || !!jobsLotPending}
            >
              {isCreate ? 'Próximo' : 'Salvar'}
            </Actions.Button>
          </Tooltip>
        </Actions>
      </Grid>
    </Grid>
  );
};
export default FormOne;
