import { memo, useEffect, useState } from 'react';

import { useCustomObservable } from 'hooks';
import { firstValueFrom } from 'rxjs';
import userService from 'services/user';
import { User } from 'services/user/types';
import { HYPERFLOW_CHAT_BLACK_ID, HYPERFLOW_CHAT_ELITE_ID, HYPERFLOW_CHAT_UNITY_ID, HYPERFLOW_FLOW_ID } from 'settings';

import TopbarHyperflowSupportChat from '@eduzz/ui-layout/Topbar/HyperflowSupportChat';
import { CurrentUser } from '@eduzz/ui-layout/Topbar/HyperflowSupportChat/types';

function SupportChat() {
  const { value: user } = useCustomObservable<User, string, string>(() => userService.getUser(), null);

  const isProducer = user?.is_producer;
  const isAccessPolicy =
    user?.acpo_original_user_cli_cod !== undefined && user?.acpo_original_user_cli_cod !== user?.cli_cod;

  const buildUserObject = (isAccessPolicy: boolean): CurrentUser => {
    const baseUser = {
      id: user?.cli_cod,
      name: user?.user_name,
      email: user?.user_email,
      belt: !user?.belt_id ? 'White' : userService.getBeltById(user?.belt_id),
      tag: !user?.is_producer ? null : user?.is_clube_black ? 'unity' : 'pro',
      isClubeBlack: user?.is_clube_black,
      isAccessPolicy
    };

    if (isAccessPolicy) {
      return {
        ...baseUser,
        originalUserId: user?.acpo_original_user_cli_cod,
        originalUserName: user?.acpo_original_user_name,
        originalUserEmail: user?.acpo_original_user_email
      } as CurrentUser;
    }

    return baseUser as CurrentUser;
  };

  const userToHyperflow: CurrentUser = buildUserObject(isAccessPolicy);

  const configToHyperflow = {
    chatUnityID: HYPERFLOW_CHAT_UNITY_ID,
    chatBlackID: HYPERFLOW_CHAT_BLACK_ID,
    chatEliteID: HYPERFLOW_CHAT_ELITE_ID,
    flowId: HYPERFLOW_FLOW_ID,
    origin: 'blinket'
  } as const;

  const createAccountsJwtPromise = () => {
    return new Promise<string>(async (resolve, reject) => {
      const ssid = user.ssid;
      try {
        const jwtToHyperflow = await firstValueFrom(userService.createSupportChatToken(ssid));
        const token = jwtToHyperflow?.data?.token;
        token ? resolve(token as string) : reject('Token not created or empty');
      } catch {
        reject('Error in token creation');
      }
    });
  };

  const createHyperflowJwtPromise = async () => {
    return new Promise<string>(async (resolve, reject) => {
      try {
        const hyperflowJwt = await firstValueFrom(userService.createHyperflowToken(userToHyperflow));
        const hyperflowChatToken = hyperflowJwt?.data?.hyperflowToken;

        if (hyperflowChatToken) {
          resolve(hyperflowChatToken as string);
        } else {
          reject('JWT not created or empty');
        }
      } catch {
        reject('Error in hyperflowChatToken creation');
      }
    });
  };

  const [hyperflowJwt, setHyperflowJwt] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (userToHyperflow.id) {
      const fetchJwt = async () => {
        try {
          const token = await createHyperflowJwtPromise();
          setHyperflowJwt(token);
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
        }
      };

      fetchJwt();
    }

    return () => {
      if (typeof Hyperflow !== 'undefined') {
        Hyperflow.clear();
      }
    };
  }, [userToHyperflow.id]);

  const shouldShow = !!isProducer && !loading && !!hyperflowJwt;

  return (
    <>
      {shouldShow && (
        <TopbarHyperflowSupportChat
          accountsJwt={createAccountsJwtPromise}
          currentUser={userToHyperflow}
          hyperflowConfig={configToHyperflow}
          hyperflowJwt={hyperflowJwt}
        />
      )}
    </>
  );
}

export default memo(SupportChat);
